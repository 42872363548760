import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Tag } from '../models/resource';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) { }

    getTagResources(tagId: number): Observable<Resource[]> {
        return this.http.get(environment.api_url + '/api/tag/' + tagId + '/resources').pipe(map((response: Tag) => {
            return response.resources;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }

    getResources(): Observable<Resource[]> {
        return this.http.get(environment.api_url + '/api/resources/basic').pipe(map((response: Resource[]) => {
            return response;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }
}
