import { ApiService } from './../../services/api.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener, ViewChildren } from '@angular/core';
import { Resource } from 'src/app/models/resource';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    private max = 0;
    private perLine = 0;
    public dimension = 0;

    @ViewChild('sectionHandle', { static: false }) sectionHandle: ElementRef;
    @ViewChildren('resourceHandle') resourceChildren: ElementRef[];

    public resourcesArray: Resource[] = [];

    constructor(private apiService: ApiService) { }

    @HostListener('window:resize', ['$event'])
    onResize() {
        console.log(this.resourceChildren);
        this.perLine = (this.sectionHandle.nativeElement.clientWidth < 700) ? 8 : 10;
        this.dimension = this.sectionHandle.nativeElement.clientWidth / this.perLine;
        this.max = Math.floor(this.sectionHandle.nativeElement.clientWidth / this.dimension)
            * Math.floor(this.sectionHandle.nativeElement.clientHeight / this.dimension) + this.perLine;
    }

    get resources() {
        console.log(this.max, this.resourcesArray.slice(0, this.max));
        return this.resourcesArray.slice(0, this.max);
    }

    ngOnInit() {
        this.apiService.getResources().subscribe((resources: Resource[]) => {
            console.log(resources);
            this.resourcesArray = resources;
            this.onResize();
        });
    }

}
