import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashComponent } from './pages/splash/splash.component';
import { ResultsComponent } from './pages/results/results.component';
import { SearchComponent } from './pages/search/search.component';
import { ProblemComponent } from './pages/problem/problem.component';
import { FeaturesComponent } from './pages/features/features.component';
import { ActionComponent } from './pages/action/action.component';
import { LazyForDirective } from './directives/lazyFor.directive';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        AppComponent,
        SplashComponent,
        ResultsComponent,
        SearchComponent,
        ProblemComponent,
        FeaturesComponent,
        ActionComponent,
        LazyForDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
